.wrap{
  background-image: url("./backgroundDiagnostic.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  button{
    background-color: white;
    padding: 15px;
    border: none;
    border-radius: 10px;
    margin: 10px;
    transition: all ease-in-out 0.5s;
     a{
       text-decoration: none;
       color: black;
       font-weight: bold;
     }
    &:hover{
      background-color: #ff9805;
      a:hover{
        color: white;
      }
    }
  }
}
.title{
    color: #000000;
    font-size: 25px;
    font-weight: bold;
  text-align: center;
  margin: 30px auto 10px;
}