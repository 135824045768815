@import "./../../styles/globals.scss";
.wrap {
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 100%;
  max-width: 800px;
  @include mobile{
    padding: 50px 10px;
  }

  .topContent {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
    }

    .logo {
      height: 150px;
      width: 150px;
    }
  }

  .content {
    font-size: 16px;
    line-height: 1.4;

    p{
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0;
    }
  }

  .bottomContent{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 2.5;
  }
}