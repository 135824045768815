@import "../../styles/variables";
.footer{
  margin-top: auto;
  background-color: $colorDark;
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 300px;
    width: 250px;

    .logo{
      position: relative;
      img{
        width: 120px;
        height: 120px;
      }

      &:after{
        content: '';
        position: absolute;
        width: 200%;
        height: 100%;
        top: 10px;
        left: -50%;
        border-bottom: 3px solid $colorGoldDark;
      }
    }

    ul{
      display: flex;
      width: 80%;
      justify-content: space-between;

      li{
        a{
          color: $colorDarkLight;
          &:hover{
            color: $colorGoldDark;
          }
        }
      }
    }
  }
}