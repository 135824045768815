.wrap{
  background-color: #eef8ff;
.content{
  position: relative;
  .title{
    position: absolute;
    background: rgba(74, 74, 74, 0.8);
    border-bottom-right-radius: 50px;
    left: 2rem;
    top: 5rem;
    max-width: 300px;
    width: 100%;
    padding: 1rem;
    h1{
      color: white;
      font-size: 1.2em;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
    }
  }
  img{
    border-top-left-radius: 90%;
    object-fit: cover;
    width: 100%;

  }

}
}

