$colorPurple: #902cff;
$colorPurpleLight: #a764ff;
$colorBlue: #4155ff;
$colorGold: #ffe30d;
$colorGoldDark: #ff9805;
$colorDark: #222222;
$colorDarkLight: #777777;
$colorGrey: #a8a8a8;
$colorGreyLight: #c7c7c7;
;
