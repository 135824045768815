.wrap{
  color: #323232;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #5e5e5e;;
  margin-bottom: 30px;
}