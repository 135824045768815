.background{
  background: linear-gradient(60deg, #e7f6ff, #9fbbff);
}
.wrap{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 1100px;
  box-sizing: border-box;

  h1{
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin: 50px;
    color: white;
  }



  .parallax{
    background-image: url("./parallax.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    min-height: 400px;
    border-bottom-right-radius: 120px;
    border-top-left-radius: 50px;
  }

  .content{
    height: 1200px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p{
      font-size: 1rem;
      font-weight: lighter;
      text-align: center;
      margin: 30px;
      position: relative;
      display: block;

      &:after{
        content: "";
        position: absolute;
        top: 50px;
        left: 0;

        height: 2px;
        max-width: 700px;
        width: 100%;
        background-color: #ff9805;
      }
    }


    .contentWrap{
      display: grid;
      margin-top: 70px;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

    }
  }
}