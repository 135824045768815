@import "../../styles/variables";
@import "../../styles/globals";
.toolbar{
  background-color: #fff;
  height: 80px;

  .burgerMenu{
    display: none;
    background-color: #ff9805;

    @include mobile{
      display: block;
    }
  }
}
.wrap{
  display: grid;
  grid-template-areas: 'logo menu login';
  grid-template-columns: 1fr 7fr 1fr;
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;


  @include mobile{
    display: flex;
    justify-content: space-between;
  }

  .empty{
    display: none;
    @include mobile{
      display: block;
    }
  }
  .logo{
    grid-area: logo;
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: all ease-in-out ;
    animation: scale 2s infinite;
    @include mobile{
      //margin-right: 200px;
    }
    @keyframes scale {
      0%{
        transform: scale(1);
        opacity: 0.8;
      }
      50%{
        transform: scale(0.95);
        opacity: 1;
      }
      100%{
        transform: scale(1);
        opacity: 0.8;
      }
    }
  }

  .menuList{
    grid-area: menu;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    justify-self: end;

    @include mobile {
      display: none;
    }


    .menuItem{
      margin: 10px;
      cursor: pointer;
      font-weight: bold;
      text-transform: uppercase;
      a{
        text-decoration: none;
        color: inherit;
        position: relative;
        display: block;
        padding: 5px;
        transition: 0.5s ;
        z-index: 3;

        &:hover{
          color: #fff;
        }

        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-top: 1px solid $colorGoldDark;
          border-bottom: 1px solid $colorGoldDark;
          transform: scaleY(2.5);
          opacity: 0;
          transition:  all ease-in-out 0.5s;
        }
        &:hover:before{
          transform: scaleY(1);
          opacity: 1;
        }
        &:after{
          content: '';
          position: absolute;
          top: 1px;
          left: 0;
          width: 100%;
          height: 100%;
          transform: scale(1.2);
          background-color: $colorGoldDark;
          opacity: 0;
          transition:  all ease-in-out 0.5s;
          z-index: -1;
        }
        &:hover:after{
          transform: scale(1);
          opacity: 1;
        }


      }
    }
  }
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 40px;


  }
  .login{
    grid-area: login;
    color: $colorGoldDark;
    cursor: pointer;
    font-size: 40px;
    transition: all ease-in-out 0.5s;
    &:hover{
      color: $colorGold;
    }
  }
}