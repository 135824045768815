.wrap{
  background: rgba(255, 170, 26, 0.1);
  border-radius: 20px;
  max-width: 700px;
  height: 100%;
  margin: 10vh auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  color: #6372ff;


  .quiz{
    display: flex;
    flex-direction: column;
    margin: 30px 10px;


  }
}