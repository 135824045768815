.wrap {
  //display: none;
  left: -50vw;
  transition: all ease-in-out 0.5s;
  .backgroundImage{
    background-image: url("./pollLinkBackground.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    .coverBlack{
      background: rgba(0,0,0,0.65);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .contentWrap{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: #e8f0ff;

        h3{
          font-weight: bold;
          font-size: 35px;
          -webkit-text-stroke-width: 2px;
          -webkit-text-stroke-color: rgba(238, 248, 255, 0.4);
          margin-bottom: 20px;
        }
        h4{
          font-size: 1.4rem;
          letter-spacing: 0.2em;
        }

        .voteLink{
          font-size: 1.5rem;
          font-weight: bold;
          letter-spacing: 0.3em;
          margin-top: 70px;
          text-transform: uppercase;
          border: 3px solid #678074;
          padding: 20px 50px;
          cursor: pointer;
          color: white;
          background: none;
          transition: all ease-in-out 0.5s;
          &:hover{
            color: black;
            background-color: white;
            border: 3px solid white;
          }
        }
      }

    }


  }
}

